<template>
  <div>
    <b-container class="mt-4">
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">Halaman <b>Konfirmasi Permohonan Surat</b></div>
      </b-alert>

      <b-alert
        v-if="status == 3"
        variant="success"
        dismissible
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
      >
        <span style="font-size: 14px">
          Anda telah
          <b>{{ status == 6 ? " menolak " : " menyetujui " }}</b> permohonan
          surat
        </span>
      </b-alert>

      <b-alert
        v-if="status == 6"
        variant="danger"
        dismissible
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
      >
        <span style="font-size: 14px">
          Anda telah
          <b>{{ status == 6 ? " menolak " : " menyetujui " }}</b> permohonan
          surat
        </span>
      </b-alert>

      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <table class="table">
                <tr>
                  <td>Nama pengaju</td>
                  <td>:</td>
                  <td>{{ data.citizen_name }}</td>
                </tr>
                <tr>
                  <td>NIK pengaju</td>
                  <td>:</td>
                  <td>{{ data.citizen_id_card_number }}</td>
                </tr>
                <tr>
                  <td>Alamat pengaju</td>
                  <td>:</td>
                  <td>{{ data.citizen_address }}</td>
                </tr>
                <tr>
                  <td>Surat yang diajukan</td>
                  <td>:</td>
                  <td>{{ data.title }}</td>
                </tr>
                <tr>
                  <td>Tanggal Pengajuan</td>
                  <td>:</td>
                  <td>{{ data.registered_date_display }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>:</td>
                  <td>
                    {{ data.letter_status_id_display }}
                    <b-button
                      variant="primary"
                      size="sm"
                      class="ml-2"
                      @click="editStatus"
                      v-if="isAnswer && isEdit"
                      >Edit</b-button
                    >
                  </td>
                </tr>
              </table>
              <div class="d-flex justify-content-start" v-if="!isAnswer">
                <b-button variant="primary" @click="changeStatus(7)"
                  >Setujui</b-button
                >
                <b-button
                  class="ml-2"
                  variant="danger"
                  @click="changeStatus(6)"
                >
                  Tolak
                </b-button>
              </div>
            </div>
          </div>
          <!-- <div class="card">
            <div class="card-body">
              <h3>Anda telah {{status == 2 ? "menolak" : }}</h3>
            </div>
          </div> -->
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      data: {},
      formLetter: {
        id: "",
        letter_status_id: "",
      },
      isAnswer: false,
      isEdit: false,
      status: "",
      showDismissibleAlert: false,
    };
  },
  methods: {
    async get() {
      this.data = await module.get(
        "letters-no-auth/" + this.$route.query.letter_id
      );
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/404");
      } else {
        console.log("data", this.data);
        console.log("data status", this.data.letter_status_id);
        if (
          this.data.letter_status_id == 6 ||
          this.data.letter_status_id == 7
        ) {
          this.status = this.data.letter_status_id;
          this.isAnswer = true;
          this.showDismissibleAlert = true;
          this.isEdit = true;
        } else if (this.data.letter_status_id == 3) {
        } else {
          this.isAnswer = true;
          this.showDismissibleAlert = true;
          this.isEdit = false;
        }
      }
    },
    editStatus() {
      this.showDismissibleAlert = false;
      this.isAnswer = false;
    },
    async changeStatus(letter_status_id) {
      let title = "Anda ingin menyetujui permohonan surat ?"
      if(letter_status_id == 6){
        title = "Anda  ingin menolak permohonan surat ?"
      }
      Swal.fire({
        title: title,
        text: "",
        icon: "question",
        showCancelButton: true,
      }).then( async (result) => {
        if (result.value) {
          this.formLetter.letter_status_id = letter_status_id;
          this.status = letter_status_id;
          let response = await module.submit(
            this.formLetter,
            `letters-no-auth/${this.formLetter.id}/change-status`
          );
          if (response.state == "error") {
            Swal.fire({
              title: "Gagal",
              text: "Gagal edit status",
              icon: "error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            // Success
            Swal.fire({
              title: response.success.title,
              text: response.success.message,
              icon: "success",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });

            this.isAnswer = true;
            this.showDismissibleAlert = true;
            this.isEdit = true;
            // if()
          }
        }
      });
      this.get();
    },
  },
  mounted() {
    this.get();
    this.formLetter.id = this.$route.query.letter_id;
  },
};
</script>

<style>
</style>